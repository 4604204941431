<template>
  <div
    v-if="!loading"
    class="d-flex flex-grow-1 wrapper"
  >
    <div
      class="d-flex flex-grow-1 justify-space-between"
      :class="{'flex-column': viewport.isPortrait}"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="background"
      >
        <g fill="#124669">
          <rect
            :x="viewport.isPortrait ? 0 : '75vw'"
            :y="viewport.isPortrait ? '60vh' : 0"
            :width="viewport.isPortrait ? '100vw': '25vw'"
            :height="viewport.isPortrait ? '40vh' : '100vh'"
          />
          <ellipse
            :cx="viewport.isPortrait ? '50vw' : '75vw'"
            :cy="viewport.isPortrait ? '60vh' : '50vh'"
            :rx="viewport.isPortrait ? '100vw' : '28vw'"
            :ry="viewport.isPortrait ? '27vh' : '60vh'"
          />
        </g>
      </svg>
      <collection-logo-text
        class="pa-4 align-self-center logo flex-grow-0"
        :class="{'logo-portrait': viewport.isPortrait}"
      />
      <v-spacer v-if="viewport.isPortrait" />
      <v-sheet
        class="pa-10 border-radius bg-plain login d-flex flex-column justify-center"
        :class="{'align-self-center': !viewport.isPortrait, 'mx-auto': !viewport.isPortrait}"
        outlined
        ref="card"
      >
        <v-text-field
          @focus="scrollCardIntoView()"
          @keyup.enter="login"
          class="flex-grow-0"
          :label="$t('t.Username')"
          v-model="username"
        />
        <v-text-field
          @focus="scrollCardIntoView()"
          @keyup.enter="login"
          class="flex-grow-0"
          :label="$t('t.Password')"
          type="password"
          v-model="password"
        />
        <v-checkbox
          :label="$t('t.RememberMe')"
          v-model="rememberMe"
        />
        <v-btn
          @click="login"
          class="border-radius"
          color="primary"
          :disabled="!username || !password"
          width="100%"
        >{{$t('t.Connect')}}</v-btn>
      </v-sheet>
      <v-spacer v-if="viewport.isPortrait" />
    </div>
    <v-snackbar
      v-model="showError"
      color="error"
      timeout=6000
    >{{ errorMessage }}</v-snackbar>
  </div>
</template>

<script>
import themeController from '@/themeController'

export default {
  name: 'login',
  inject: ['viewport'],
  components: {
    CollectionLogoText: () => import('@/pages/mobility/collection-logo-text.vue')
  },
  async created () {
    await themeController.loadDefaultTheme().catch(() => { })
    const token = this.$route.query?.session
    if (token) {
      if (await this.$http().authenticateFromToken(token)) {
        await this.redirect()
      }
    }
    const authentication = JSON.parse(window.localStorage.getItem('authentication'))
    if (authentication?.expiration > Date.now()) {
      const isTokenValid = await this.$http().authenticateFromLocalStorage()
      if (isTokenValid) {
        await this.redirect()
      }
    }
    this.loading = false
  },
  data () {
    return {
      errorMessage: undefined,
      loading: true,
      password: undefined,
      username: undefined,
      rememberMe: false,
      showError: false
    }
  },
  methods: {
    async login () {
      if (!this.username || !this.password) { return }
      try {
        await this.$http().authenticate(this.username, this.password, this.rememberMe)
        this.redirect()
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.errorMessage = this.$t('t.InvalidUserNameOrPassword')
          this.showError = true
        } else if (e.response.status === 403) {
          this.errorMessage = this.$t('t.UserAccountIsLocked')
          this.showError = true
          this.isLocked = true
        } else if (e.response.status === 406) {
          const response = e.response.data

          this.$router.push(`/changePassword/${response.hash}?error=${response.error}`)
        } else {
          this.errorMessage = this.$t('t.InternalError')
          this.showError = true
        }
      }
    },
    async redirect () {
      return await this.$router.replace({ path: '/mobility' })
    },
    scrollCardIntoView () {
      setTimeout(
        () => { this.$refs.card.$el.scrollIntoView({ behavior: 'smooth', block: 'end' }) },
        250
      )
    }
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  height 100%

.background
  background-color white
  height 100%
  left 0
  position fixed
  top 0
  width 100%

.logo
  margin 0 auto
  max-width 75%
  z-index 0
  flex-basis 35%

.logo-portrait
  flex-basis 24%

.login
  min-width 30vw
  height fit-content
  z-index 0
</style>
